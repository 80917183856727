import { Fragment } from 'react';

import { Feedback } from 'molecules/feedbacks';
import { Skeleton } from 'molecules/skeletons';
import { listBuilder } from 'utils';

const ItemList = ({ icon = 'database', title, text, data = [], loading, count, children, tailwind }) => {
	// Render
	return (
		<Fragment>
			{loading ? (
				<Fragment>
					{listBuilder(count).map((v, i) => (
						<Skeleton type='item' key={i} />
					))}
				</Fragment>
			) : (
				<Fragment>
					{data.length > 0 ? (
						<Fragment>{children}</Fragment>
					) : (
						<Feedback type='preview' icon={icon} title={title} text={text} tailwind={tailwind} />
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export { ItemList };
