import { Fragment } from 'react';

import { Col, Grid } from '@playbooks/interface/grid';
import { Feedback } from 'molecules/feedbacks';
import { Skeleton } from 'molecules/skeletons';
import { listBuilder } from 'utils';

const ProfileList = ({
	type = 'profile',
	icon = '',
	title = '',
	text = '',
	data = [],
	renderItem,
	count = 3,
	selected = [],
	loading = false,
	taskRunning = false,
	rootLink = '',
	onClick,
	onNext,
	children,
	tailwind,
}) => {
	const props = { selected, loading, taskRunning, rootLink, onClick, onNext, children, tailwind };
	const RenderItem = props => renderItem(props);

	// Render
	return (
		<Fragment>
			{data.length > 0 || loading ? (
				<Grid cols='grid-cols-12 xl:grid-cols-10' {...tailwind?.grid}>
					{loading ? (
						<Fragment>
							{listBuilder(count).map((v, i) => (
								<Col key={i} sm='6' md='4' lg='3' xl='2' {...tailwind?.col}>
									<Skeleton type={type} tailwind={tailwind} />
								</Col>
							))}
						</Fragment>
					) : (
						<Fragment>
							{data?.map((item, i) => (
								<Col key={i} sm='6' md='4' lg='3' xl='2' {...tailwind?.col}>
									<RenderItem item={item} {...props} />
								</Col>
							))}
							{children && (
								<Col sm='6' md='4' lg='3' xl='2' {...tailwind?.col}>
									{children}
								</Col>
							)}
						</Fragment>
					)}
				</Grid>
			) : (
				<Feedback type={type} icon={icon} title={title} text={text} tailwind={tailwind?.feedback} />
			)}
		</Fragment>
	);
};

export { ProfileList };
